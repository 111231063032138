import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll';

const Ul = styled.ul`

    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    /* margin: 0; */
    /* color: #7e7e7e; */
    color: white;
    /* font-weight: 400; */

    li {
        padding: 15px 15px;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        /* display: none; */
        flex-flow: column nowrap;
        /* justify-content: center; */
        /* align-items: center; */
        background-color: #272727;
        position: fixed;
        transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
        top: 0 !important;
        right: 0;
        height: 100vh;
        width: 100%;
        padding-top: 100px;
        padding-left: 50px;
        transition: transform 0.3s ease-in-out;

        li {
            /* color: white; */
            font-size: 24px;
            font-weight: bolder;
        }
    }

`

const RightNav = ({ open, setOpen }) => {
    return (
        <Ul open={open} setOpen={setOpen}>
            <Link 
            activeClass="active"
            onClick={() => setOpen(!open)}
            to="home"
            spy={true} 
            smooth={true}
            offset={-70}>
                <li>Home</li>
            </Link>
            <Link
            activeClass="active" 
            onClick={() => setOpen(!open)}
            to="services"
            spy={true} 
            smooth={true}
            offset={-70}>
                <li>Services</li>
            </Link>
            <Link
            activeClass="active" 
            onClick={() => setOpen(!open)}
            to="about"
            spy={true} 
            smooth={true}
            offset={-70}>
                <li>About</li>
            </Link>
            <Link
            activeClass="active" 
            onClick={() => setOpen(!open)}
            to="projects"
            spy={true} 
            smooth={true}
            offset={-70}>
                <li>Projects</li>
            </Link>
            <Link
            activeClass="active" 
            onClick={() => setOpen(!open)}
            to="contact"
            spy={true} 
            smooth={true}
            offset={-70}>
                <li>Contact</li>
            </Link>
        </Ul>
    )
}

export default RightNav
