import React, { useState } from 'react'
import styled from 'styled-components'
import Burger from './Burger'
import './Navigation.css'

const Nav = styled.nav`
    height: 70px;
    /* border-bottom: 2px solid #f1f1f1; */
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
    background-color: transparent;
    transition: all 300ms;
    /* width: 100%; */
    padding: 0 5px 0 20px;
    max-width: 1200px;
    margin: 0 auto;

    .logo {
        padding: 15px 0;
    }

    @media (max-width: 768px) {
        padding: 0 20px;
        /* justify-content: space-between; */
    }
`

const Navbar = () => {

    const [navbar, setNavbar] = useState(false)
    const changeNavbarBg = () => {
        if(window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeNavbarBg)
    
    return (
        <div className={navbar ? 'navbar' : 'container'}>
            <Nav >
                <div className="logo">
                    <div className="logo-main">
                        <svg height="40" viewBox="0 0 760 760" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="380" cy="380" r="360" stroke="#DEAC27" strokeWidth="40"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M80 381C80 222.035 203.639 91.9478 360 81.6561V680.344C203.639 670.052 80 539.965 80 381ZM165 381C165 297.522 211.494 224.903 280 187.626V574.374C211.494 537.097 165 464.478 165 381Z" fill="#DEAC27"/>
                            <path d="M680 381C680 539.965 556.361 670.052 400 680.344V461H480V574.5C546.5 535 577.5 485 591.5 421.5H400V81.6561C529.289 90.166 636.206 180.58 669.353 301.5H580C563 257 528.5 214.5 480 188V342.5H677.553C679.167 355.105 680 367.956 680 381Z" fill="#DEAC27"/>    
                        </svg>
                    </div>
                    <div className="logo-container">
                        <span className="logo-name">DELTA SIERRA</span>
                        <span className="logo-subname">Construction and Trading Corp.</span>
                    </div>

                </div>
                <Burger />
                
            </Nav>
        </div>
        
    )
}

export default Navbar
